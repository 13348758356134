import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Timeline } from "@/components/timeline";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";

const WebAppDevelopment = () => {
  return (
    <>
      <GatsbySeo
        title="Best Web development Company in Chennai | Wep App Developer"
        description="We are Best Web development Company in Chennai. As well as we are Professional Wep App Developer Providing Top Services."
        canonical="https://www.tectratechnologies.com/web-app-development/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/web-app-development/",
          title:
            "Best Web development Company in Chennai | Wep App Developer",
          description:
            "We are Best Web development Company in Chennai. As well as we are Professional Wep App Developer Providing Top Services.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id":
                "https://www.tectratechnologies.com/web-app-development/",
              url: "https://www.tectratechnologies.com/web-app-development/",
              name: "Best Web development Company in Chennai | Wep App Developer",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-06-18T09:32:45+00:00",
              dateModified: "2022-05-10T17:38:52+00:00",
              description:
                "We are Best Web development Company in Chennai. As well as we are Professional Wep App Developer Providing Top Services.",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/web-app-development/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: [
                    "https://www.tectratechnologies.com/web-app-development/",
                  ],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/web-app-development/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Web App Development",
                },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Custom web solutions that boost engagement &amp; sales.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              Why tectra for your web development services?
            </h2>
            <p className="max-w-5xl mx-auto text-base text-black text-center font-poppins font-thin leading-8 mt-4">
              With the experience of delivering our projects from global leaders
              to startups, we have come a long way. Our team thinks big to
              develop smart projects. As a recognized web development company,
              we provide you with the following:
            </p>
            <div className="mt-8">
              <div className="w-full h-full">
                <StaticImage
                  src="../../images/pKRNxEguRgM.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={500}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 mt-8 lg:mt-12">
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Great Performance
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    We provide you high a performing website with a modern and
                    smart design. Our team uses high-end UX to minimize errors
                    and increase the speed of the website. All-in-all, we
                    provide you with great digital exposure.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Customized Solutions
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    The option of customization is beneficial for you in two
                    ways. Firstly, you get a design according to your needs, and
                    secondly, you are paying only for the functionalities that
                    you need on your website. Thus, we provide you with a
                    cost-effective &amp; customized package.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Scalability
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Customization gives you an opportunity of selecting
                    technology according to you. This helps you in choosing
                    websites on which you can scale you are business in the
                    future. Also, our business specialists give you the best
                    advice for every solution.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Confidentiality
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Maintaining the confidentiality of your work is our first
                    and foremost concern. Our developers are highly secure and
                    they keep all your information confidential to avoid data
                    leak problems. So you don't need to worry about your
                    information as it is in safe hands.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Responsive design
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Website is a core product for every business as users
                    understand your brand's mission, vision, &amp; quality with
                    your website. Thus, by keeping these factors in our mind we
                    created responsive designs that can work on every device.
                    Our website responds to users' behavior and environment
                    based on screen, orientation, platform, and size.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Agile Approach
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    We create every project with an agile approach that makes
                    sure that you are not spending excessive time on non-core
                    operations. We try to add value to every project and every
                    operation by reducing time and increasing efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bg-white relative overflow-hidden mt-10 lg:mt-0">
          <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center pb-4 lg:pr-12 sm:mx-0 sm:pb-0 lg:col-span-6 order-2 lg:order-1">
                <div className="relative z-10 gap-x-4 px-5 lg:px-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <p className="text-base text-black font-poppins font-thin leading-8 mb-0">
                    Our web development team specializes in developing
                    end-to-end solutions as per varying requirement
                    specifications of businesses. From creating static
                    informative websites to building online stores to setting up
                    blogging platforms to designing and developing heavily
                    animated web portals, we can do it all. With proficiency in
                    PHP, Laravel, CakePHP, CodeIgniter, Symphony, and other web
                    development frameworks, we can analyze the best-suited tools
                    and technologies for every business model.
                  </p>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4 mb-0">
                    Tectra Technologies puts customer satisfaction at the heart
                    of every project. Valuing our clients and their customers
                    equally, we strive to build websites and web platforms that
                    everybody falls in love with. Our carefully designed
                    development processes and standards help our programmers
                    develop high-quality web applications. We follow structured
                    programming practices, use the latest frameworks and
                    development tools, comply with coding guidelines, and carry
                    out detailed research to create web solutions that transcend
                    our clients’ expectations.
                  </p>
                </div>
              </div>
              <div className="lg:col-span-6 order-1 lg:order-2 mb-2 lg:mb-2">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <div className="w-full h-full">
                    <StaticImage
                      src="../../images/shahadat-rahman-BfrQnKBulYQ-unsplash2.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                  <div className="w-full h-full mt-4">
                    <StaticImage
                      src="../../images/daniel-korpai-pKRNxEguRgM-unsplash1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Process
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "Discussion",
                  "Analysis with Time & Cost Estimation",
                  "Design, Conception & Plan – Development & Testing",
                  "Development & Testing",
                  "Deployment & Post Production checks",
                ]}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default WebAppDevelopment;
